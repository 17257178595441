import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useCorporatesList() {
  const corporateListTable = ref([])
  const tableColumns = [
    { text: 'NAME', value: 'name' },
    { text: 'EMAIL', value: 'email', sortable: false },
    { text: 'LOCATION', value: 'location', sortable: false },
    { text: 'QUANTITY', value: 'quantity', sortable: false },
    { text: 'AMOUNT', value: 'paid', sortable: false },
    { text: 'PROGRESS', value: 'progress', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalCorporatesTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  //Get Corporates
  const fetchCorporates = () => {
    store
      .dispatch('corporate/fetchCorporates', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(response => {
        corporateListTable.value = response.data['result']
        totalCorporatesTable.value = 190

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const resolveVaccineQuantities = item => {
    var request = resolveRequestedQuantity(item.vaccination_requests)
    var vaccinated = item.vaccinations.length

    return `${vaccinated}/${request}`
  }

  const resolveVaccineProgress = item => {
    var request = resolveRequestedQuantity(item.vaccination_requests)
    var vaccinated = item.vaccinations.length

    if (request == 0) return '0.00'
    return ((vaccinated / request) * 100).toString().toAccounting()
  }

  const resolveVaccineAmount = requests => {
    return (resolveRequestedQuantity(requests) * 10).toString().toAccounting()
  }

  const resolveRequestedQuantity = requests => {
    var requested = 0
    requests.forEach(function(item) {
      requested += item['quantity'] ?? 0
    })
    return requested
  }

  String.prototype.toAccounting = function() {
    return parseFloat(this)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchCorporates()
  })

  return {
    corporateListTable,
    tableColumns,
    searchQuery,
    totalCorporatesTable,
    loading,
    options,
    selectedRows,
    fetchCorporates,
    resolveVaccineQuantities,
    resolveVaccineAmount,
    resolveVaccineProgress,
  }
}
