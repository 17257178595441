var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"corporate-list"}},[_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v(" Corporates ")]),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"corporate-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","rounded":"","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.corporateListTable,"options":_vm.options,"server-items-length":_vm.totalCorporatesTable,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 25, 50, 100]
    },"items-per-page":25,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"color":"primary","size":"32"}},[(item.icon)?_c('v-img',{attrs:{"src":require(("@/assets/images/company/" + (item.icon)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{name: 'corporate-details', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',[_vm._v("Company Type")])],1)],1)]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [(item.email)?_c('span',{staticClass:"text--primary text-truncate"},[_vm._v(_vm._s(item.email))]):_c('span',{staticClass:"opacity-3"},[_vm._v("email@example.com")])]}},{key:"item.location",fn:function(ref){
    var item = ref.item;
return [(item.location)?_c('span',{staticClass:"text--primary text-truncate"},[_vm._v(_vm._s(item.location))]):_c('span',{staticClass:"opacity-5"},[_vm._v("N/A")])]}},{key:"item.quantity",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text--primary text-center"},[_vm._v(_vm._s(_vm.resolveVaccineQuantities(item)))])]}},{key:"item.paid",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text--primary"},[_vm._v("$"+_vm._s(_vm.resolveVaccineAmount(item.vaccination_requests)))])]}},{key:"item.progress",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.resolveVaccineProgress(item))+"%")]),_c('v-progress-linear',{staticClass:"project-progress mt-1",attrs:{"height":"6","rounded":"","color":_vm.resolveProgressVariant(_vm.resolveVaccineProgress(item)),"value":5}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"small btn-inline-link",staticStyle:{"opacity":"0.6px"},attrs:{"small":"","text":"","outlined":"","rounded":"","color":"info","to":{name: 'corporate-details', params: { id: item.id }}}},[_vm._v(" View ")])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }