<template>
  <div id="corporate-list">
    <!-- alert colors-->
    <v-card
      class="mb-6"
    >
      <v-card-title>
        Corporates
      </v-card-title>
      <v-divider class="mt-0"></v-divider>
      <!-- actions -->

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="corporate-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">

          <v-btn
            color="secondary"
            rounded
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
    <!-- Data table -->
    <v-data-table
      v-model="selectedRows"
      :headers="tableColumns"
      :items="corporateListTable"
      :options.sync="options"
      :server-items-length="totalCorporatesTable"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100]
      }"
      :items-per-page="25"
      show-select
      >
        <!-- Name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="32"
            >
              <v-img
                v-if="item.icon"
                :src="require(`@/assets/images/company/${item.icon}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{name: 'corporate-details', params: { id: item.id }}"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{item.name}}
              </router-link>
              <small>Company Type</small>
            </div>
          </div>
        </template>

        <!--Email-->
        <template #[`item.email`]="{item}">
          <span
            v-if="item.email"
            class="text--primary text-truncate">{{item.email}}</span>
          <span
            v-else
            class="opacity-3">email@example.com</span>
        </template>

        <!--Location/Town-->
        <template #[`item.location`]="{item}">
          <span
            v-if="item.location"
            class="text--primary text-truncate">{{item.location}}</span>
          <span
            v-else
            class="opacity-5">N/A</span>
        </template>

        <!-- quantity -->
        <template #[`item.quantity`]="{item}">
          <div class="text--primary text-center">{{ resolveVaccineQuantities(item) }}</div>
        </template>

        <!-- paid -->
        <template #[`item.paid`]="{item}">
          <div class="text--primary">${{ resolveVaccineAmount(item.vaccination_requests) }}</div>
        </template>

        <!-- progress -->
        <template #[`item.progress`]="{item}">
          <span>{{ resolveVaccineProgress(item) }}%</span>
          <v-progress-linear
            height="6"
            rounded
            class="project-progress mt-1"
            :color="resolveProgressVariant(resolveVaccineProgress(item))"
            :value="5"
          ></v-progress-linear>
        </template>

        <template #[`item.actions`]="{item}">
          <v-btn
            small
            text
            outlined
            rounded
            color="info"
            class="small btn-inline-link"
            :to="{name: 'corporate-details', params: { id: item.id }}"
            style="opacity: 0.6px"
          >
            View
          </v-btn>
        </template>
      </v-data-table>
      </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '../StoreModule'
import useCorporatesList from './useCorporatesList'
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'
import resolvers from '@/mixins/resolvers'

export default {
  components: {},
  mixins: [resolvers],
  setup() {
    const CORPORATES_STORE_MODULE_NAME = 'corporate'
    if (!store.hasModule(CORPORATES_STORE_MODULE_NAME)) store.registerModule(CORPORATES_STORE_MODULE_NAME, storeModule)

    const {
      corporateListTable,
      tableColumns,
      searchQuery,
      totalCorporatesTable,
      loading,
      options,
      selectedRows,
      fetchCorporates,
      resolveVaccineQuantities,
      resolveVaccineAmount,
      resolveVaccineProgress,
    } = useCorporatesList()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CORPORATES_STORE_MODULE_NAME)) store.unregisterModule(CORPORATES_STORE_MODULE_NAME)
    })

    return {
      corporateListTable,
      tableColumns,
      searchQuery,
      totalCorporatesTable,
      options,
      loading,
      selectedRows,
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
      avatarText,
      fetchCorporates,
      resolveVaccineQuantities,
      resolveVaccineAmount,
      resolveVaccineProgress,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/preset/preset/apps/user.scss';
#corporate-list .corporate-search {
  max-width: 250px;
}
</style>
